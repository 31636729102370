import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import tw from 'twin.macro';
import useHover from '../../../hooks/useHover';
import Block from './Block';
import Image from '../../../components/Image/Image';
import { useTrackingEvent } from '../../../utils/tracking';

const StyledLinkBlock = styled(Block)`
    ${tw`bg-black-100 font-montserrat font-extrabold text-xl`}
`;

const LinkBlockImage = styled(Image)`
    ${tw`absolute h-full w-full object-cover transition duration-200`}
    ${(props) => props.hover && tw`opacity-15`}
`;

const LinkBlockTitle = styled.h3`
    ${tw`z-10 text-white`}
`;

const LinkBlock = ({
  title, to, backgroundImage, trackingEvent,
}) => {
  const [hoverRef, isHovered] = useHover();
  const trackingEventHandler = useTrackingEvent();
  return (
    <StyledLinkBlock to={to} ref={hoverRef} onClick={trackingEventHandler(trackingEvent)}>
      <LinkBlockTitle>{title}</LinkBlockTitle>
      { backgroundImage && (
      <LinkBlockImage
        hover={isHovered}
        src={backgroundImage}
      />
      )}
    </StyledLinkBlock>
  );
};

LinkBlock.defaultProps = {
  backgroundImage: null,
  trackingEvent: {},
};

LinkBlock.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  trackingEvent: PropTypes.object,
};

export default LinkBlock;
