import styled from 'styled-components';
import tw from 'twin.macro';

const ListItem = styled.li`
  ${tw`font-lato font-light text-sm lg:text-base leading-relaxed tracking-wide`}
  &:before {
    content: '- ';
  }
`;

export default ListItem;
