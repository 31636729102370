/* eslint-disable no-irregular-whitespace */
import React from 'react';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import styled from 'styled-components';
import Blocks from './Blocks/Blocks';
import ContentBaseline from './ContentBaseline/ContentBaseline';
import ContentContainer from './ContentContainer/ContentContainer';
import ContentTitle from './ContentTitle/ContentTitle';
import Image from '../../../components/Image/Image';
import List from './Blocks/List/List';
import ListItem from './Blocks/ListItem/ListItem';
import Section from './Section/Section';
import Title from './Title/Title';
import Block from './Blocks/Block/Block';

const TitleItem = styled.span`
  ${tw`leading-tiny block`}
`;

const PrestationPalette = ({
  sectionTitle,
  blockColor,
  contentImage,
  contentTitle,
  contentBaseline,
  contentList,
}) => (
  <Section>
    <Title>
      {sectionTitle.map((titleItem) => <TitleItem>{titleItem}</TitleItem>)}
    </Title>
    <Blocks>
      <Image src={contentImage} />
      <Block bgColor={blockColor}>
        <ContentContainer>
          <ContentTitle>
            {contentTitle.map((titleItem) => <TitleItem>{titleItem}</TitleItem>)}
          </ContentTitle>
          <ContentBaseline>{contentBaseline}</ContentBaseline>
          <List>
            {contentList.map((item) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </List>
        </ContentContainer>
      </Block>
    </Blocks>
  </Section>
);

PrestationPalette.defaultProps = {
  blockColor: 'color-5',
};
PrestationPalette.propTypes = {
  sectionTitle: PropTypes.element.isRequired,
  blockColor: PropTypes.string,
  contentImage: PropTypes.string.isRequired,
  contentTitle: PropTypes.element.isRequired,
  contentBaseline: PropTypes.string.isRequired,
  contentList: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default PrestationPalette;
