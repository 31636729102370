/* eslint-disable no-unused-expressions */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseSection from '../../../components/Section/Section';
import LinkBlock from './LinkBlock';
import Block from './Block';

const Section = styled(BaseSection)`
  ${tw`mb-8`}
`;

const PrestationBlocks = styled.div`
  ${tw`flex justify-center flex-col sm:flex-row`}
`;

const StaticBlock = styled(Block)`
  ${tw`bg-prune-100`}
`;

const Title = styled.h2`
  ${tw`font-montserrat`}
`;

const TitleTop = styled.span`
  ${tw`block font-bold uppercase text-2xl`}
`;

const TitleBottom = styled.span`
  ${tw`block font-medium`}
`;

const Prestations = () => (
  <Section>
    <PrestationBlocks>
      <StaticBlock as="div">
        <Title>
          <TitleTop>Découvrez</TitleTop>
          <TitleBottom>mes prestations</TitleBottom>
        </Title>
      </StaticBlock>
      <LinkBlock
        trackingEvent={{
          action: 'Click',
          category: '[Home]',
          label: 'Click on architecture prestations',
        }}
        to="/architecture-interieur"
        title="Architecture"
        backgroundImage="https://images.ctfassets.net/r346nz3uql81/3aqAdd4zgAqMtLBQ4NfmwC/5ed53ed16232912f0ad64673e057ec61/lagoet_home_prestation_archi.jpeg?w=650&q=50&fit=fill&"
      />
      <LinkBlock
        trackingEvent={{
          action: 'Click',
          category: '[Home]',
          label: 'Click on decoration prestations',
        }}
        to="/decoration-interieur"
        title="Décoration"
        backgroundImage="https://images.ctfassets.net/r346nz3uql81/2hkw6xBkRtcOWreYNnaz37/0035ba281b2acfb1c49af9fb234e1f75/lagoet_home_prestation_deco.jpeg?w=650&q=50&fit=fill"
      />
    </PrestationBlocks>
  </Section>
);

export default Prestations;
