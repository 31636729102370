/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import BaseSection from '../../../components/Section/Section';
import BaseImage from '../../../components/Image/Image';
import Blocks from './Blocks/Blocks';

const Section = styled(BaseSection)`
${tw`pt-16 sm:pt-32 max-w-screen-xl mx-auto mb-0 sm:mb-24`}
`;

const LeftBlock = styled.div`
  ${tw`flex items-center`}
`;

const LeftBlockConent = styled.div`
${tw`w-full px-8 lg:px-16`}
`;

const Image = styled(BaseImage)`
${tw`h-full w-full mx-auto object-cover`}
`;

const RightBlock = styled.div``;

const Title = styled.h2`
${tw`font-montserrat font-bold text-2xxl sm:text-4xl lg:text-5xl text-gold tracking-wider mb-8 sm:mb-12`}
line-height: 1.15;
`;

const Text = styled.p`
${tw`font-lato text-sm lg:text-base`}
`;
const BR = styled.br``;

const Description = () => (
  <Section>
    <Blocks>
      <LeftBlock>
        <LeftBlockConent>
          <Title>DÉCORATION & ARCHITECTURE D’INTÉRIEUR</Title>
          <Text>
            <strong>
              J’accompagne mes clients dans la réalisation de leur projet de rénovation et/ou décoration en Île-de-France et bien plus loin encore.
            </strong>
          </Text>
          <BR />
          <Text>
            Soucieuse du détail, j’aime particulièrement agencer les espaces, coordonner couleurs et matériaux, harmoniser mobilier et accessoires… afin de créer
            {' '}
            <strong>des intérieurs de caractère</strong>
            .
          </Text>
          <Text>
            Amoureuse de la nature, je m’inspire de ses nuances, de ses lumières et de ses textures pour apporter émotion et équilibre à mes créations.
          </Text>
          <Text>
            J’aime les intérieurs cosy, les notes végétales, le noir et la couleur, les touches sophistiquées, les lignes épurées et le bien-être que peut procurer
            {' '}
            <strong>un lieu au style singulier</strong>
            …
          </Text>
        </LeftBlockConent>
      </LeftBlock>
      <RightBlock>
        <Image src="//images.ctfassets.net/r346nz3uql81/6JIShZkIwukhFe7IQBzuCe/ee04589af09424549dbe0160ce7b7124/lagoet_home_Stmartin_salon.jpg?w=1280&q=50&fit=fill&f=center" />
      </RightBlock>
    </Blocks>
  </Section>
);

export default Description;
