export default {
  hero: {
    title: ['Anne-sophie Lagoët', 'Architecte d\'intérieur'],
    img: 'https://images.ctfassets.net/r346nz3uql81/7eH1k8O1tETlgCG8F1VEmK/634724175e5cae5e4b6591bc6d2beb60/lagoet_architecture_home_papier_peint.jpg?w=2300&q=50&fm=jpg&fit=fill&f=bottom',
  },
  prestations: {
    sectionTitle: ['« AU COEUR DE VOTRE PROJET.»'],
    blockColor: 'black-100',
    contentImage: 'https://images.ctfassets.net/r346nz3uql81/71urDqsxLwtRtOP92mzb8b/b4d5ebf9e5e372f1102947b1cc3b4d39/lagoet_home_carrelage.jpg?w=2300&q=50&fit=fill&f=center',
    contentTitle: ['UNE EXPERIENCE', 'SUR-MESURE'],
    contentBaseline: 'Intervenir dans votre projet de manière partielle ou totale pour une prestation adaptée à vos besoins et à votre budget :',
    contentList: [
      'recherche de style',
      'agencement de l’espace',
      'plans techniques',
      'sélection matériaux',
      'menuiserie sur-mesure',
      'sélection mobilier, textiles & accessoires',
      'croquis 3D couleur',
      'suivi de chantier…',
    ],
  },
  projects: [
    {
      image: 'https://images.ctfassets.net/r346nz3uql81/wqLJu5DM9K2FW5JU2v4o5/5759ff12956df96c27bfec7c2279a2eb/annesophielagoet_archi_diaporama_12.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      title: 'Archi commerciale',
      description: "Monocle la boutique d'optique",
      url: '/realisations',
    },
    {
      image: 'https://images.ctfassets.net/r346nz3uql81/6qozbcJd8duY7D6OZEYt6L/f2ddddc66db65f6eb4d52fb08ee8f950/annesophielagoet_archi_diaporama_10.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      title: "Archi d'intérieur",
      description: "L'appartement de courbevoie",
      url: '/realisations',
    },
    {
      image: 'https://images.ctfassets.net/r346nz3uql81/4a4oAHPaWdaXAJq05A6eGU/025ebd145ef75026698d639b79e537a6/annesophielagoet_deco_diaporama_06.jpg?w=1334&h=1334&q=50&fm=jpg&fit=fill',
      title: 'Décoration',
      description: "Chambre d'ado Genevoise",
      url: '/realisations',
    },
  ],
  profilPhoto: {
    url: 'https://images.ctfassets.net/r346nz3uql81/79WMWZdX7NQdbR5DiSAFj3/56a2143af4a5609b9641e0fd5864a574/A-S-Lagoet-01176-HD.jpg?w=2300',
  },
};
