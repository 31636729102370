/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import styled from 'styled-components';
import tw from 'twin.macro';
import Page from '../../components/Page/Page';
import BaseSection from '../../components/Section/Section';
import Hero from './HomeHero/HomeHero';
import data from './data';
import Description from './Description/Description';
import Block from './Block/Block';
import Prestations from './Prestations/Prestations';
import PrestationPalette from './PrestationsPalette/PrestationPalette';
import ProjectIdentity from './ProjectIdentity/ProjectIdentity';
import More from '../../widgets/More/More';

const Title = styled.p`
    ${tw`font-montserrat font-medium text-2xl sm:text-4xl leading-tight tracking-wider`}
`;

const Section = styled(BaseSection)`
  ${tw`max-w-screen-md mx-auto w-full sm:w-11/12`}
`;

const Home = ({ pageContext: { seo } }) => {
  const {
    hero: { title: heroTitle, img: heroImg },
    prestations: {
      sectionTitle, blockColor, contentImage, contentTitle, contentBaseline, contentList,
    },
    projects,
    profilPhoto,
  } = data;

  return (
    <>
      <Page
        seo={seo}
        header={() => (
          <Hero
            offset="4rem"
            title={heroTitle}
            imageSrc={heroImg}
          />
        )}
        main={() => (
          <>
            <Description />
            <Section>
              <Block>
                <Title>
                  « JEUX DE LUMIERE,
                  <br />
                  COULEURS ET MATIERES. »
                </Title>
              </Block>
            </Section>
            <Prestations />
            <More
              buttonLabel="Me contacter"
              title="vous avez un projet à me confier ?"
              buttonSrc="/contact"
              trackingEvent={{
                action: 'Click',
                category: '[Home]',
                label: 'Click on me contacter',
              }}
            />
            <PrestationPalette
              sectionTitle={sectionTitle}
              blockColor={blockColor}
              contentImage={contentImage}
              contentBaseline={contentBaseline}
              contentTitle={contentTitle}
              contentList={contentList}
            />
            <ProjectIdentity sectionTitle="« UN PROJET, UNE IDENTITE… »" projects={projects} photo={profilPhoto} />
            {/* <More
              buttonLabel="Mes réalisations"
              title="vous souhaitez en voir plus ?"
              buttonSrc="/realisations"
            /> */}
          </>
        )}
      />
    </>
  );
};

export default Home;
