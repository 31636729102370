import styled from 'styled-components';
import tw from 'twin.macro';

const bgColors = {
  prune: tw`bg-prune`,
  'color-11': tw`bg-color-11`,
  'black-100': tw`bg-black-100`,
};

const Block = styled.div`
  ${tw`flex flex-col items-center justify-center md:transform md:translate-y-16 py-16 px-8 text-white`}
  ${({ bgColor }) => bgColors[bgColor]}
`;

export default Block;
