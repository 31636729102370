import React from 'react';
import PropTypes from 'prop-types';
import Section from './Section/Section';
import Title from './Title/Title';
import Image from '../../../components/Image/Image';

const ProjectIdentity = ({ sectionTitle, photo }) => (
  <Section>
    <Title>
      {sectionTitle}
    </Title>
    <Image src={photo.url} />
  </Section>
);

ProjectIdentity.propTypes = {
  sectionTitle: PropTypes.string.isRequired,
  photo: PropTypes.string.isRequired,
};

export default ProjectIdentity;
