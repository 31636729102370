import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import tw from 'twin.macro';
import Hero from './Hero/Hero';
import HeroBox from './HeroBox/HeroBox';
import HeroImageBox from './HeroImageBox/HeroImageBox';
import Title from '../../../components/Title/Title';
import HeroImage from './Hero/HeroImageBox/HeroImageBox';
import NavigationBar from '../../../widgets/NavigationBar/NavigationBar';

const TextTop = styled.div`
    ${tw`font-montserrat font-bold text-3xl sm:text-5xl uppercase text-white tracking-wider leading-none mb-2`}
    animation-name: topFadeIn;
    animation-duration: 2.5s;
    @keyframes topFadeIn {
      0% {
        transform: translateY(-50%);
        opacity: 0;
      }
    
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
`;

const TextBottom = styled.div`
    ${tw`font-montserrat font-light text-base sm:text-xl uppercase text-white tracking-widest`}
    animation-name: BottomFadeIn;
    animation-duration: 2.5s;
    @keyframes BottomFadeIn {
      0% {
        transform: translateY(50%);
        opacity: 0;
      }
    
      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }
`;

const TextStyle = {
  0: TextTop,
  1: TextBottom,
};
const HomeHero = ({ offset, title, imageSrc }) => (
  <>
    <Hero offset={offset}>
      <HeroBox>
        <Title>
          {title.map((text, index) => {
            const Component = TextStyle[index];
            return (
              <Component>{text}</Component>
            );
          })}
        </Title>
      </HeroBox>
      <HeroImageBox>
        <HeroImage src={imageSrc} />
      </HeroImageBox>
    </Hero>
    <NavigationBar />
  </>
);

HomeHero.defaultProps = {
  offset: '0px',
  title: null,
  imageSrc: null,
};

HomeHero.propTypes = {
  offset: PropTypes.string,
  title: PropTypes.string || PropTypes.arrayOf(PropTypes.string),
  imageSrc: PropTypes.string,
};

export default HomeHero;
